import React from 'react'
import { Route, Link, Switch } from "react-router-dom";
import Drink from './Drink'
import IceCream from './IceCream';
import ItalianIce from './ItalianIce';
import Popsicle from './Popsicle';
import Button from '../Componets/Buttons'
import Card from '../Componets/Card'
import BootstrapCard from '../Componets/Card';
import { AiOutlineArrowRight } from "react-icons/ai";

const Menu = (props) => {
    return(
        <div className="cards">
         <BootstrapCard/>
          </div>
       )
}
export default Menu