const Footer = () => {
    return(
        <div className="footer">
        <hr></hr>
        <h2>Ice Cream Brings Us Together</h2>
        <h4>Copy Right @ Anthony Demeo, Christopher Johnson & Stephanie Liu</h4>
        <img src="https://i.pinimg.com/originals/25/7e/03/257e031b4ffd8845adcb67d53e0fe61d.gif"></img>
        </div> 
    )
}
export default Footer